const ACCENT_CARACTERS =
  "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
const NON_ACCENT_CARACTERS =
  "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";

export const slugify = (value: string) => {
  return [...value.trim().toLowerCase()]
    .map((caracter) => {
      const accentIndex = ACCENT_CARACTERS.indexOf(caracter);

      if (accentIndex < 0) return caracter;

      return NON_ACCENT_CARACTERS[accentIndex];
    })
    .join("")
    .replaceAll(/[^\d a-z-]/g, "")
    .replaceAll(/\s+/g, "-")
    .replaceAll(/-+/g, "-");
};
